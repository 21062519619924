<i18n lang="json">
{
  "ru": {
    "text": "Пожалуйста, обновите страницу и повторите поиск",
    "action": "Обновить страницу"
  },
  "en": {
    "text": "Please refresh the page and retry the search",
    "action": "Reload page"
  }
}
</i18n>

<template>
  <UIModalLight 
    v-model="showReloadModal"
    class="modal-reload"
    :hide-close-btn="true"
  >
    <div
      class="modal-reload--inner"
      :class="{'medium': getScreenWidth < 1490}"
    >
      <img 
        src="~/assets/img/search/search-reload.svg"
        alt="search-variants"
      />
      <div class="modal-reload--title">
        {{ t('text') }}
      </div>
      <UIButton
        class="modal-reload--action"
        button-theme="dark"
        @click="reloadPage"
      >
        {{ t('action') }}
      </UIButton>
    </div>
  </UIModalLight>
</template>

<script setup>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import UIModalLight from "@/components/UIKit/UIModalLight";
import UIButton from "@/components/UIKit/UIButton.vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n();
const store = useStore();
const showReloadModal = ref(false);

watch(props.show, (isShow) => {
  showReloadModal.value = isShow;
}, {
  immediate: true,
});

const getScreenWidth = computed(() => store.state.user.screenWidth);

function reloadPage() {
  location.reload();
}

</script>

<style lang="scss" scoped>
.modal-reload {
  z-index: 40;
  &--inner {
    padding: 20px;
    max-width: 300px;
    text-align: center;
    &.medium {
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      margin: 0 auto;
      max-width: none;
    }
  }
  &--title {
    color: #1C1C1C;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 10px;
  }
  &--action {
    margin-top: 20px;
    border-radius: 10px;
  }

  @media screen and (max-width: 768px) {
    align-items: flex-end;
    &:deep(.ui-modal-light) {
      width: 100%;
      border-radius: 20px 20px 0 0;
    }
  }
}
</style>
