import sutochnoENV from "@sutochno/env";
import { HTTP } from "@/components/api";

/**
 * long-polling
 *
 * @module pushUser
 * @return {object} Promise
 */
const isDEV = sutochnoENV.DEV; 

export default (data) =>
  //disable mocking userData for prerender
  isDEV
    ? null
      // new Promise((res) => {
      //   // эмуляция апи
      //   setTimeout(
      //     () =>
      //       res({
      //         data: {
      //           channel: "push-user-top",
      //           eventid: "",
      //           id: 2070,
      //           tag: "1",
      //           text: "=%7B%22callback%22%3A%22_pushTopList%22%7D",
      //           time: "Thu, 11 Feb 2021 08:55:28 GMT"
      //         }
      //       }),
      //     5000
      //   );
      // })
    : HTTP.get(`/sub/push-user-top`, {
        params: {
          callfunc: "actionPolling",
          v: new Date().getTime(),
        },
        cache: false,
      });
