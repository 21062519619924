
const matomoWrapper = (payload = []) => {
  try {
    if (typeof window === "undefined") {
      return;
    }
    if (!window?._paq) {
      return;
    }
    window._paq.push(payload);
  } catch (e) {
    console.error(e, "error in pushing to matomo");
  }
};

export const setEcommerceView = ({
  productSKU,
  productName,
  categoryName,
  price,
} = {}) => {
  matomoWrapper([
    "setEcommerceView",
    productSKU,
    productName,
    categoryName,
    price,
  ]);

  matomoWrapper(['trackPageView']);
};

export const addEcommerceItem = ({
  productSKU,
  productName,
  productCategory,
  price,
  quantity,
  result,
} = {}) => {
  matomoWrapper([
    "addEcommerceItem",
    productSKU,
    productName,
    productCategory,
    price,
    quantity,
  ]);

  matomoWrapper(['trackEcommerceCartUpdate', result && typeof result === "number" ? Math.floor(result) : 0]); 
};

export const trackEcommerceOrder = ({
  orderId,
  grandTotal,
  subTotal,
} = {}) => {
  matomoWrapper([
    "trackEcommerceOrder",
    orderId,
    grandTotal,
    subTotal,
  ]);
};

export default {
  setEcommerceView,
  trackEcommerceOrder,
  addEcommerceItem,
};
  