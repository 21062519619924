<i18n lang="json">
  {
    "ru": {
      "startSearch": "Найти"
    },
    "en": {
      "startSearch": "Search"
    }
  }
</i18n>

<template>
  <UIButton
    class="apply-search default"
    data-cy="search"
    button-theme="red"
    :class="[
      (isDisabled || isLoading) && 'apply-search_loading loading',
      isActive && 'apply-search_active'
    ]"
    :disabled="isDisabled || isLoading"
  >
    <div class="apply-search__content">
      <transition name="btn">
        <span
          v-if="isActive"
          class="apply-search__text"
        >
          {{ t("startSearch") }}
        </span>
      </transition>
      <img
        src="~/assets/img/icon/icon_navigation_search.svg"
        alt="search-icon"
      />
    </div>
  </UIButton>
</template>

<script setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import UIButton from "@/components/UIKit/UIButton.vue";

defineProps({
  isActive: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
});

const { t } = useI18n();
const store = useStore();

const isLoading = computed(() => store.state.search.listSuggestions.isLoading);

</script>

<style lang="scss" scoped>
.apply-search {
  &.default {
    width: fit-content;
    padding: 0 14px;
    min-width: 52px;
    height: 52px;
    border-radius: 50px;
    align-self: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    transition: none;
  }

  &__text {
    display: inline-block;
    overflow: hidden;
    width: 62px;
  }

  &__content {
    display: flex;
  }

  &_active {
    width: 100%;
  }

  &_loading {
    opacity: 1;
  }

  .btn-enter-active,
  .btn-leave-active {
    transition: width 0.3s;
    width: 62px;
  }

  .btn-enter-from,
  .btn-leave-to {
    width: 0px;
  }
}
</style>
