import { HTTP } from "../index";
import sutochnoENV from "@sutochno/env";
/**
 * getNewNotices
 *
 * @module getNewNotices
 * @return {object} Promise
 */
const isDEV = sutochnoENV.DEV;

export default () =>
  //disable mocking userData for prerender
  isDEV
    ? new Promise((res) => {
        // эмуляция апи
        setTimeout(
          () =>
            res({
              data:
                // Заявка на бронь аннулирована
                // {
                //   "success": true,
                //   "data": {
                //     "notices": [{
                //       "id": "26605653",
                //       "token": "Cron_OrderAutoRejectUnreadOwner",
                //       "seven_id": "8941929",
                //       "type": "site",
                //       "user_id": "4292155",
                //       "html": "<div role=\"alert\" class=\"alert alert-push alert-danger alert-dismissible fade in jsn-push-note\">\r\n\t<button aria-label=\"Close\" data-dismiss=\"alert\" class=\"close\" type=\"button\"><span aria-hidden=\"true\">×</span></button>\r\n\t<h4>Заявка на бронь аннулирована</h4>\r\n\t<p>\r\n\t\tБарыш, Красноармейская улица, 38, к 2 — заявка №8941929 аннулирована: вы не приняли решение вовремя.\r\n\t</p>\r\n\t\r\n\t\t\r\n\t<a\r\n\t\thref=\"http://evgenij.test/cabinet/notices/8941929/\"\r\n\t\tclass=\"btn btn-alert-push\"\r\n\t>\r\n\t\tОткрыть переписку\r\n\t</a>\r\n</div>",
                //       "date_add": "2019-12-16 09:55:14"
                //     },{
                //       "id": "26605653",
                //       "token": "Cron_OrderAutoRejectUnreadOwner",
                //       "seven_id": "8941929",
                //       "type": "site",
                //       "user_id": "4292155",
                //       "html": "<div role=\"alert\" class=\"alert alert-push alert-danger alert-dismissible fade in jsn-push-note\">\r\n\t<button aria-label=\"Close\" data-dismiss=\"alert\" class=\"close\" type=\"button\"><span aria-hidden=\"true\">×</span></button>\r\n\t<h4>Заявка на бронь аннулирована</h4>\r\n\t<p>\r\n\t\tБарыш, Красноармейская улица, 38, к 2 — заявка №8941929 аннулирована: вы не приняли решение вовремя.\r\n\t</p>\r\n\t\r\n\t\t\r\n\t<a\r\n\t\thref=\"http://evgenij.test/cabinet/notices/8941929/\"\r\n\t\tclass=\"btn btn-alert-push\"\r\n\t>\r\n\t\tОткрыть переписку\r\n\t</a>\r\n</div>",
                //       "date_add": "2019-12-16 09:55:14"
                //     }]
                //   },
                //   "errors": [],
                //   "actions": [],
                //   "platform": "js",
                //   "uuid": "1576480177",
                //   "timestamp": 1576479315
                // }

                // Клиент отменил заявку
                // {
                //   "success": true,
                //   "data": {
                //     "notices": [{
                //       "id": "26605655",
                //       "token": "OrdersCancelByClient",
                //       "seven_id": "8941936",
                //       "type": "site",
                //       "user_id": "4292155",
                //       "html": "<div role=\"alert\" class=\"alert alert-push alert-danger alert-dismissible fade in jsn-push-note\">\r\n\t<button aria-label=\"Close\" data-dismiss=\"alert\" class=\"close\" type=\"button\"><span aria-hidden=\"true\">×</span></button>\r\n\t\t\t<h4>Клиент отменил заявку</h4>\r\n\t\t<p>\r\n\t\t\tЗаявка №8941936 на объект Троицк, Переулок Мира, Харьков, Украина улица, 14 отменена клиентом.\r\n\t\t</p>\r\n\t\t<a\r\n\t\thref=\"http://evgenij.test/cabinet/notices/8941936/\"\r\n\t\tclass=\"btn btn-alert-push\"\r\n\t>\r\n\t\tОткрыть переписку\r\n\t</a>\r\n</div>",
                //       "date_add": "2019-12-16 10:07:34"
                //     }]
                //   },
                //   "errors": [],
                //   "actions": [],
                //   "platform": "js",
                //   "uuid": "1576480301",
                //   "timestamp": 1576480054
                // }

                // Новое сообщение по бронированию
                {
                  success: true,
                  data: {
                    notices: [
                      {
                        id: "26605643",
                        token: "OrdersNewMessage",
                        seven_id: "8941922",
                        type: "site",
                        user_id: "4292155",
                        html:
                          '<div role="alert" class="alert alert-push alert-info alert-dismissible fade in jsn-push-note">\r\n\t<button aria-label="Close" data-dismiss="alert" class="close" type="button"><span aria-hidden="true">×</span></button>\r\n\t<h4>Новое сообщение по бронированию</h4>\r\n\t<p>\r\n\t\tНовое сообщение по бронированию №8941922 — Городок-17, Ясеневая переулок, 17\r\n\t</p>\r\n\t<a\r\n\t\thref="http://evgenij.test/cabinet/notices/8941922/"\r\n\t\tclass="btn btn-alert-push"\r\n\t>\r\n\t\tПрочитать сообщение\r\n\t</a>\r\n</div>',
                        date_add: "2019-12-13 14:59:47",
                      },
                      {
                        id: "26605643",
                        token: "OrdersNewMessage",
                        seven_id: "8941922",
                        type: "site",
                        user_id: "4292155",
                        html:
                          '<div role="alert" class="alert alert-push alert-info alert-dismissible fade in jsn-push-note">\r\n\t<button aria-label="Close" data-dismiss="alert" class="close" type="button"><span aria-hidden="true">×</span></button>\r\n\t<h4>Новое сообщение по бронированию</h4>\r\n\t<p>\r\n\t\tНовое сообщение по бронированию №8941922 — Городок-17, Ясеневая переулок, 17\r\n\t</p>\r\n\t<a\r\n\t\thref="http://evgenij.test/cabinet/notices/8941922/"\r\n\t\tclass="btn btn-alert-push"\r\n\t>\r\n\t\tПрочитать сообщение\r\n\t</a>\r\n</div>',
                        date_add: "2019-12-13 14:59:47",
                      },
                      {
                        id: "26605643",
                        token: "OrdersNewMessage",
                        seven_id: "8941922",
                        type: "site",
                        user_id: "4292155",
                        html:
                          '<div role="alert" class="alert alert-push alert-info alert-dismissible fade in jsn-push-note">\r\n\t<button aria-label="Close" data-dismiss="alert" class="close" type="button"><span aria-hidden="true">×</span></button>\r\n\t<h4>Новое сообщение по бронированию</h4>\r\n\t<p>\r\n\t\tНовое сообщение по бронированию №8941922 — Городок-17, Ясеневая переулок, 17\r\n\t</p>\r\n\t<a\r\n\t\thref="http://evgenij.test/cabinet/notices/8941922/"\r\n\t\tclass="btn btn-alert-push"\r\n\t>\r\n\t\tПрочитать сообщение\r\n\t</a>\r\n</div>',
                        date_add: "2019-12-13 14:59:47",
                      },
                    ],
                  },
                  errors: [],
                  actions: [],
                  platform: "js",
                  uuid: "1576234771",
                  timestamp: 1576238387,
                },
            }),
          3000
        );
      })
    : HTTP.post("json/noticer/getNewNotices", {
        cache: false,
      });
