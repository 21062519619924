<template>
  <div class="main__notfound">
    <Header />

    <BaseNotFound />

    <Footer v-if="!isNotShowFooter" />
  </div>
</template>

<script setup>
import { Header } from "@sutochno/header";
import { Footer } from "@sutochno/footer";
import BaseNotFound from "@/components/base/BaseNotFound.vue";
import wl from "@/composables/wl";

const { isNotShowFooter } = wl();
</script>

<style lang="scss" scoped>
.main__notfound {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f1f3fb;
}
</style>
