import HTTP from "../http-common";
/**
 * Получение списка локаций
 *
 * @module getTermSuggestionsWithBoundingBox
 * @return {object} Promise
 */
export default (query) => {
  return HTTP.get(
    "rest/search/getTermSuggestionsWithBoundingBox?query=" + query,
    {
      headers: {
        "api-version": String(2), // Если указать версию, то будет минимум данных
      },
    }
  )
  // .then((v) => v.data.data.suggestions);
};
