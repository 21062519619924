import sutochnoENV from "@sutochno/env";
import { isServer } from "@sutochno/utils";
// [MDN Reference](https://developer.mozilla.org/docs/Web/API/Storage)
const isDev = sutochnoENV.DEV;

const warnServer = () => isDev ? console.log('\x1b[33m%s\x1b[0m', "Browser storage not working on server") : {};
const STORAGE = {
  length: 0,
  clear: warnServer,
  getItem: warnServer,
  key: warnServer,
  removeItem: warnServer,
  setItem: warnServer,
}

export const sessionStorage = isServer ? STORAGE : window.sessionStorage;
export const localStorage = isServer ? STORAGE : window.localStorage;
