import pageview from "./pageview";
import { watch, computed } from "vue";
import { useStore } from "vuex";
import { isServer } from "@sutochno/utils";

export const usePageTracker = () => {
  if (isServer) return;
  
  const route = useRoute();
  const store = useStore();

  const pageviewAction = (auth = { isAuth: false, id: "" }) =>
    pageview({
      page_title: document.title,
      page_location: location.href,
      page_referrer: document.referrer,
      user_id: auth.isAuth ? auth.id : "",
    });

  const init = () => {
    const authFromGetter = () => {
      const auth = store.getters["user/auth"];
      return { isAuth: auth?.value?.isAuth, id: auth?.value?.data?.id ?? "" };
    };

    pageviewAction(authFromGetter());
  };

  const watchPath = () => {
    const authFromState = computed(() => {
      const userAuth = store.state.user.auth;
      return {
        isAuth: userAuth.isAuth,
        id: userAuth.data?.id ?? "",
      };
    });

    watch(
      () => route.path,
      () => {
        pageviewAction(authFromState.value);
      }
    );
  };

  init();
  watchPath();
};
