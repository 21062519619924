
export default ({
  eventCategory,
  eventAction,
  eventName,
  eventValue,
} = {}) => {
  try {
    if (typeof window === "undefined") {
      return;
    }
    if(!window?._paq) {
      return;
    }
    const eventData = JSON.stringify(eventName);

    window._paq.push([
      "trackEvent",
      eventCategory,
      eventAction,
      eventData,
      eventValue
    ]);
  } catch (e) {
    console.log(e, "error in pushing to matomo")
  }
}

export function experimentWithUsers(){
  // эксперимент с пользователями VUECLIENT-2344
  return new Promise((resolve) => {
    function createExperiment() {
      const Experiment = Matomo.AbTesting.Experiment;

      new Experiment({
        name: 'Similarity_ranking_v1', // you can also use '7' (ID of the experiment) to hide the name
        percentage: 100,
        includedTargets: [{"attribute":"url","inverted":"0","type":"any","value":""}],
        excludedTargets: [],
        startDateTime: '2024/12/18 00:00:00 UTC',
        endDateTime: '2025/01/31 23:59:59 UTC',
        variations: [
            {
                name: 'original',
                activate: function (event) {
                  resolve(false)
                }
            },
            {
                name: 'Variation1', 
                activate: function(event) {
                  resolve(true)
                }
            }
        ],
        trigger: function () {
            return true;
        }
      });
    }

    setTimeout(() => {
      resolve(false)
    }, 500)
    if ('object' === typeof Piwik && 'object' === typeof Matomo.AbTesting) {
        createExperiment();
    } else {
        window.matomoAbTestingAsyncInit = createExperiment;
    }

  })
}
