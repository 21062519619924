import sutochnoENV from "@sutochno/env";
import HTTP from "../http-common";

/**
 * Получение данных о странах и городах из файла JSON,
 * который расположен ./public/countries.json
 *
 * @module getLocations
 * @return {object} Promise
 */
export default (locale) => {
  let isExtranet = location?.host.includes("extranet") || location?.host.includes("webview");
  let locationHost = isExtranet
    ? document?.location?.origin
    : sutochnoENV.VITE_BASEPATH;
  if (locale == "en") {
    return HTTP.get(`${locationHost}/main_all_locations_en.json`);
  } else {
    return HTTP.get(`${locationHost}/main_all_locations_ru.json`);
  }
};
