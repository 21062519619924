<i18n lang="json">
  {
    "ru": {
      "bookingGuarantee": "Найдём, где остановиться!",
      "calendarTitle": "Календарь",
      "cityOrCountry": "Курорт, город или адрес",
      "cityTitle": "Выбрать направление",
      "guest": "0|{n} гость|{n} гостя|{n} гостей",
      "moreThanVariants": "330 тысяч вариантов жилья для поездок по России и зарубежью",
      "apartmentsHotels": "Квартиры, отели и гостевые дома",
      "occupiedIn": "Заезд",
      "occupiedOut": "Отъезд",
      "startSearch": "Найти",
      "title": "Судак, Крым",
      "numberOfOptions": "816 вариантов",
      "when": "Когда"
    },
    "en": {
      "bookingGuarantee": "Let's find a place to stay!",
      "calendarTitle": "Calendar",
      "cityOrCountry": "City, country",
      "cityTitle": "",
      "guest": "0|{n} guest|{n} guests|{n} guests",
      "moreThanVariants": "330 thousand accommodation options for trips in Russia and abroad",
      "apartmentsHotels": "Apartments, hotels and guest houses",
      "occupiedIn": "Check in",
      "occupiedOut": "Check out",
      "startSearch": "Search",
      "title": "Sudak, Crimea",
      "numberOfOptions": "816 options",
      "when": "When"
    }
  }
</i18n>

<template>
  <div
    class="search-widget"
    v-click-outside="clickOutside"
    @keydown.esc="clickOutside"
    tabindex="0"
  >
    <div
      class="search-widget-field tmp-font--average"
      :class="{ active: isWidgetActive }"
    >
      <BaseSuggestions
        ref="baseSuggestions"
        class="search-widget-field--suggestions"
        :longList="true"
        @doSearch="doSearch"
        @focus="closeCalendar"
      />

      <div
        :style="{ zIndex: 1 }"
        class="search-widget-field--occupied"
      >
        <div
          data-cy="checkIn"
          class="search-widget-field--occupied__in"
          :class="{
            'search-widget-field--occupied__in-fill': issetDateIn,
            'search-widget-field--occupied__in-active':
              activeIn || activeFieldIn,
            'search-widget-field--occupied__in-active-choice':
              (activeIn || activeFieldIn) && issetDateIn,
          }"
          @click="checkInOpen(), gaCheckIn()"
        >
          <div class="title" :class="{ active: issetDateIn }">
            {{ t("occupiedIn") }}
          </div>
          <div class="date">
            <template v-if="viewsDateIn">
              {{ viewsDateIn }}
            </template>
            <template v-else>
              {{ t("when") }}
            </template>
          </div>
        </div>

        <div
          data-cy="checkOut"
          class="search-widget-field--occupied__out"
          :class="searchWidgetClasses"
          @click="checkOutOpen(), gaCheckOut()"
        >
          <div class="title" :class="{ active: issetDateOut }">
            {{ t("occupiedOut") }}
          </div>
          <div class="date">
            <template v-if="viewsDateOut">
              {{ viewsDateOut }}
            </template>
            <template v-else>
              {{ t("when") }}
            </template>
          </div>
        </div>

      <div class="calendar-in" ref="calendarWrapper">
        <DatePicker
          ref="calendar"
          v-model="date"
          :fill-mode="fillMode"
          v-model:date-views="dateViews"
          :show-header="!dateViews.in || !dateViews.out"
          :lang="$i18n.locale"
          @visibleCalendar="visibleCalendar = $event"
          @reset="handleCalendarDateReset"
          @dateType="dateType"
        />
      </div>
    </div>

      <div
        class="search-widget-field__right"
        :class="{ active: isGuestFocused }"
      >
        <Guests
          class="search-widget-field--guest"
          @clickGuests="clickGuests"
          ref="selectGuests"
        />
        <ApplySearch
          class="search-widget-field__apply"
          :is-active="isWidgetActive"
          :is-disabled="isBtnDisabled"
          @click="doSearch"
        />
      </div>
    </div>
    <QuickLocationSelection
      class="search-widget-select-city"
      @locationSelected="preselectCity"
    />
  </div>
</template>

<script>
// Нужно отрефакторить компонент и перенести на composition api

import { mapState, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { useSearchGAS7, useCommonParams, useIsS7 } from "@/composables/gtag/partners/s7/index";

// TODO ОБЪЕДЕНИТЬ КЛОН С ОРИГИНАЛОМ УЧИТЫВАЯ ВСЕ МЕСТА ГДЕ ВЫЗЫВАЕТСЯ ОРИГИНАЛ
import DatePicker from "./datepickerext/src/DatePickerExt.vue";
// import DatePicker from "@sutochno/datepickerext";

import Guests from "@/components/desktop/Main/searchWidget/Guests.vue";
import ApplySearch from "@/components/desktop/Main/searchWidget/ApplySearch.vue";
// TODO объеденить с оригиноалом
import BaseSuggestions from "@/components/base/BaseSuggestionsUpdated/";
import UIButton from "@/components/UIKit/UIButton.vue";
import { useI18n } from "vue-i18n";
import QuickLocationSelection from "@/components/base/Main/QuickLocationSelection.vue";

export default {
  components: {
    DatePicker,
    Guests,
    BaseSuggestions,
    UIButton,
    QuickLocationSelection,
    ApplySearch
  },
  setup() {
    const { t } = useI18n();

    const commonParams = useCommonParams()
    const isS7 = useIsS7()
    const callS7GA = () => {
      if (!isS7.value) {
        return;
      }
      const searchS7GA = useSearchGAS7("search", commonParams.value);
      searchS7GA();
    };

    return {
      t,
      callS7GA,
    };
  },
  data() {
    return {
      city: null,
      isBtnDisabled: false,
      visibleCalendar: false,
      activeFieldIn: false,
      activeFieldOut: false,
      date: {
        in: null,
        out: null,
      },
      dateViews: {
        in: null,
        out: null,
      },
      viewsDateIn: null,
      viewsDateOut: null,
      isBlockMainFocus: true,
      dateShortcutTimer: null,
      calendarDateTypesEvents: {
        'today': {
          matomo: 'Today',
          gtag: 'dates_today',
        },
        'tomorrow': {
          matomo: 'Tomorrow',
          gtag: 'dates_tomorrow',
        },
        'weeks': {
          matomo: 'Weekend',
          gtag: 'dates_holiday',
        },
      },
    };
  },
  watch: {
    selectedVariant: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.$nextTick(() => {
          if (val && !this.checkIn && !this.checkOut) {
            this.checkInOpen();
            this.gaCheckIn();
          }
          if (this.listType === "history") {
            this.doSearch();
          }
        });
      },
    },
    checkIn(val) {
      this.viewsDateIn = val ? this.format(val) : "";
      if (val && !this.dateShortcutTimer) {
        this.sendMatomo('Calendar_SelectDateStart', val);
      }
    },
    checkOut(val) {
      this.viewsDateOut = val ? this.format(val) : "";
      if (val && !this.dateShortcutTimer) {
        this.sendMatomo('Calendar_SelectDateEnd', val);
      }
    },
    "dateViews.in"(val) {
      if (val) {
        this.activeFieldIn = false;
      }
    },
    date: {
      deep: true,
      handler(val) {
        if (val) {
          this.$store.dispatch("search/setCalendarDates", {
            checkIn: val.in,
            checkOut: val.out,
          });
        }
      },
    },
    visibleCalendar(val) {
      this.setCalendarFocused(val);
      if (val) {
        this.$nextTick(() => {
          const element = this.$refs.calendar.$refs.padding;

          if (
            this.getScreenViewport === "laptop" &&
            window.innerHeight >
              this.$refs.calendar.$refs.baseModalRef.$el.getBoundingClientRect()
                .bottom
          ) {
            return;
          }
          scrollToElement(element);

          function scrollToElement(element) {
            let scrollTimeout;
            element.scrollIntoView({
              block: "end",
              behavior: "smooth",
            });

            // document.addEventListener('scroll', scrollListener);
            // function scrollListener (e) {
            //   clearTimeout(scrollTimeout);
            //   scrollTimeout = setTimeout(function() {
            //     window.scrollBy({
            //         top: 20,
            //         behavior: 'smooth'
            //     });
            //     document.removeEventListener("scroll", scrollListener)
            //   }, 100);
            // }
          }
        });
        return;
      }
      if (this.checkIn && this.checkOut) {
        this.dateViews.in = this.checkIn;
        this.dateViews.out = this.checkOut;
        return;
      }
      this.dateViews.in = this.date.in;
    },
    term(val) {
      if (val != "" && !this.dateViews.in) {
        //TO DO: не нашел calendarIn а из-за него ошибка вываливается на тестировании пока закоментил
        // setTimeout(() => {
        //   this.$refs.calendarIn.show();
        // }, 50);
      }
      this.$nextTick(() => {
        if (val && !this.date.in && !this.date.out) {
          this.$refs.calendar.show();
        }
      });
      if (this.listType === "history") {
        this.doSearch();
      }
    },
    dateFromHistorylist(val, oVal) {
      if (val.in != oVal.in || val.out != oVal.out) {
        this.date = val;
        this.dateViews = val;
      }
    },
  },
  mounted() {
    document.addEventListener("keypress", this.keypressEnter);
    this.$store.dispatch("search/reset");

    if (this.location?.title && this.location?.id !== 1) {
      this.setTerm(this.location.title);
    }
    this.$nextTick(() => {
      this.date = {
        in: this.checkIn,
        out: this.checkOut,
      };
      this.viewsDateIn = this.checkIn ? this.format(this.checkIn) : "";
      this.viewsDateOut = this.checkOut ? this.format(this.checkOut) : "";

      if (!this.checkIn || !this.checkOut) {
        sendEventGtag("dates_sa", {
          click: "main_dates_no",
        });
      } else {
        sendEventGtag("dates_sa", {
          click: "main_dates_yes",
        });
      }
    });
  },
  unmounted() {
    document.removeEventListener("keypress", this.keypressEnter);
  },
  methods: {
    ...mapMutations("search", [
      "setCalendarFocused",
      "setChildrenWithoutAgeError",
      "setTerm",
    ]),
    dateType(type) {
      if (this.dateShortcutTimer) {
        clearTimeout(this.dateShortcutTimer);
      }

      this.dateShortcutTimer = setTimeout(() => {
        const { matomo, gtag } = this.calendarDateTypesEvents[type] || {};
        this.sendMatomo('Calendar_ClickFirstSelect', matomo);
        this.sendDateGtag(gtag);
        this.dateShortcutTimer = null;
      }, 0);
    },
    handleCalendarDateReset() {
      this.handleReset();
      this.sendMatomo('Calendar_ClickReset');
    },
    sendDateGtag(click) {
      sendEventGtag("dates_sa", {
        click,
      });
    },
    clickGuests() {
      sendEventGtag("dates_sa", {
        click: "main_guests",
      });
      this.closeCalendar();
    },
    closeCalendar() {
      if (this.visibleCalendar) {
        this.$refs.calendar?.close();
      }
    },
    handleReset() {
      this.activeFieldIn = false;
      this.activeFieldOut = false;
    },
    sendMatomo(action, name) {
      const data = {
        eventCategory: "Search",
        eventAction: action,
      }

      if (name) {
        data.eventName = name;
      }
      matomoHelper(data);
    },
    checkInOpen() {
      if (this.dateViews.in) {
        this.activeFieldOut = false;
        this.activeFieldIn = true;
      }
      this.$refs.calendar.show();
    },
    gaCheckIn() {
      sendEventGtag("dates_sa", {
        click: "main_dates_in",
      });
    },
    checkOutOpen() {
      if (this.dateViews.out) {
        this.activeFieldIn = false;
        this.activeFieldOut = true;
      }
      this.$refs.calendar.show();
    },
    gaCheckOut() {
      sendEventGtag("dates_sa", {
        click: "main_dates_out",
      });
    },
    preselectCity(city) {
      if (
        this.$children &&
        this.$children.find((item) => item.sight) &&
        this.$children.find((item) => item.sight).sight
      ) {
        this.$children.find((item) => item.sight).sight = null;
      }
      this.city = city; //HACK
    },
    clickOutside(evt) {
      if (evt?.target?.closest(".suggestions-list-elem ")) {
        return;
      }

      this.handleReset();
      this.closeCalendar();
      this.$refs.baseSuggestions?.clickOutside();
      this.$refs.selectGuests?.close();
    },
    format(date) {
      if (!(date instanceof Date)) return "";
      const day = date.toLocaleString(this.$i18n.locale, {
        day: "numeric",
      });
      const month = date
        .toLocaleString(this.$i18n.locale, {
          month: "short",
        })
        .slice(0, 3);
      const weekday = date.toLocaleString(this.$i18n.locale, {
        weekday: "short",
      });
      return `${day} ${month}, ${weekday}`.toLocaleLowerCase();
    },
    getFormatOccupied(date) {
      const format = (date) => {
        const month = (date.getMonth() + 1).toString().padStart(2, 0),
          day = date.getDate().toString().padStart(2, 0),
          year = date.getFullYear();
        return year + "-" + month + "-" + day;
      };
      return date.in && date.out
        ? format(date.in) + ";" + format(date.out)
        : null;
    },
    // selectCity(cityData) {
    //   this.$store.dispatch("search/setLocation", cityData);
    // },
    async doSearch() {
      if (this.isChildrenWithoutAgeError) {
        this.setChildrenWithoutAgeError(true);
        this.$refs["selectGuests"].showSelect(true);
        return;
      }
      this.setChildrenWithoutAgeError(true);
      if (this.termEmpty) {
        if (!this.$refs.baseSuggestions.focused) {
          // таймер позволяет сработать после мотода "clickOutside" у компонента "BaseSuggestions"
          setTimeout(() => {
            this.$refs.baseSuggestions.$refs.input.focus();
          }, 100);
        }
        this.$refs.baseSuggestions.focused = true;
      } else if (!this.date.in || !this.date.out) {
        setTimeout(() => {
          this.checkInOpen();
        }, 100);
      } else {
        this.callS7GA();

        this.isBtnDisabled = true;

        const occupied = {
          in: this.date.in,
          out: this.date.out,
        };

        const hotelIDS =
          this.selectedVariant?.data?.type === "hotel" &&
          this.getIDsOfSelectedVariant;

        const query = _.pickBy({
          occupied: this.getFormatOccupied(occupied),
          guests_adults: this.guests.adults,
          guests_childrens: this.guests.childrens.join(","),
          // id: this.location.id,
          // type: this.location.type,
          term: this.term,
          ids: hotelIDS || null,
        });

        if (this.guests?.pets?.value) {
          query.pets = 1;
        }

        if (this.analiticParamForSearchWidList?.type === "history") {
          query.search_history = 1;
        } else if (this.analiticParamForSearchWidList?.type === "hotels") {
          query.search_hotel = this.analiticParamForSearchWidList.value;
        }

        if(this.$route.name === undefined) {
          query.from = 404;
        }

        await this.$nextTick();
        this.sendMatomo('ClickSearch');
        this.$router
          .push({
            name: "Search",
            query,
          })
          .catch((err) => console.error(err));
      }
    },
    keypressEnter(event) {
      if (event.which === 13 || event.keyCode === 13) {
        if (this.isBlockMainFocus) {
          if (!this.location.bbox && !this.authState && !this.selectedVariant) {
            this.$refs.baseSuggestions.showList();
            return false;
          }
          if (!this.checkIn && !this.checkOut) {
            // this.$refs.calendar.show();
            return false;
          }
          if (this.location.bbox && this.checkIn && this.checkOut) {
            this.doSearch();
            return true;
          }
        }
      }
    },
    focusBlockMain() {
      this.isBlockMainFocus = true;
    },
    blurBlockMain() {
      this.isBlockMainFocus = false;
    },
  },
  computed: {
    ...mapState("user", ["whitelabel", "authState"]),
    ...mapState("search", [
      "location",
      "checkIn",
      "checkOut",
      "guests",
      "term",
      "selectedVariant",
      "dateFromHistorylist",
      "analiticParamForSearchWidList",
      "listType",
      "isWidgetFocused",
      "isCalendarFocused",
      "isGuestFocused",
    ]),
    ...mapGetters("search", [
      "termEmpty",
      "getIDsOfSelectedVariant",
      "isChildrenWithoutAgeError",
    ]),
    ...mapGetters("user", ["auth", "getScreenViewport"]),

    searchWidgetClasses() {
      return {
        'search-widget-field--occupied__out-fill': this.issetDateOut,
        'search-widget-field--occupied__out-active': this.activeOut || this.activeFieldOut,
        'search-widget-field--occupied__in-active-choice': (this.activeOut || this.activeFieldOut) && this.issetDateOut,
      }
    },

    isWidgetActive() {
      return (
        this.isWidgetFocused || this.isCalendarFocused || this.isGuestFocused
      );
    },
    issetDateIn() {
      return Boolean(this.viewsDateIn);
    },
    issetDateOut() {
      return Boolean(this.viewsDateOut);
    },
    activeIn() {
      return Boolean(
        this.visibleCalendar && !this.issetDateIn && !this.issetDateOut
      );
    },
    activeOut() {
      return Boolean(
        this.visibleCalendar && this.issetDateIn && !this.issetDateOut
      );
    },
    fillMode() {
      if (this.activeFieldIn) {
        return "in";
      }
      if (this.activeFieldOut) {
        return "out";
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.main, .notfound {
  .search-widget {
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;

    &:focus {
      outline: none;
    }

    &-field {
      position: relative;
      min-height: 58px;
      display: flex;
      z-index: 2;
      background: #ffffff;
      box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
        0px 5px 10px rgba(20, 45, 136, 0.02);
      border-radius: 100px;
      // transition: max-width 0.3s;

      // FIX Intend
      .choice-city::before {
        text-indent: 15px;
      }

      &__apply {
        width: 100%;
      }
    }

    // выбор направления
    .search-widget-field--suggestions {
      min-width: 268px;
      border-right: 1px solid #ececec;
      transition: min-width ease 0.2s;
    }
    &-select-city {
      margin-top: 20px;
      margin-bottom: 67px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      grid-gap: 8px;

      span {
        &.title {
          color: var(--color-gray-g5);
        }
        &.val {
          display: inline-block;
          padding: 3px 11px;
          cursor: pointer;
          color: var(--color-main-black);
          background: var(--color-gray-g3);
          backdrop-filter: blur(15px);
          border-radius: 11px;
        }
      }
    }

    // выбор дат
    &-field--occupied {
      display: flex;
      min-width: 232px;
      width: 100%;
      background: #fff;
      @media (max-width: 930px) {
        min-width: 210px;
      }
      &__in,
      &__out {
        padding: 0 18px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // max-width: 107px;
        width: 50%;
        height: 100%;
        white-space: nowrap;
        cursor: pointer;

        transition: background-color 0.2s;
        &-active {
          background: var(--color-gray-g1);
        }
        .title {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: var(--color-gray-g5);
        }
        .date {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--color-main-black);
        }
      }
      &__out {
        border-right: 1px solid #ececec;
        border-left: 1px solid #ececec;
      }
    }
    .calendar {
      &-in,
      &-out {
        color: #444;
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        width: 1000px;
        max-width: 100%;
        // left: 90px;
      }
      &-out {
        left: 90px;
      }
    }
    .search-widget-field--occupied__out-active::before,
    .search-widget-field--occupied__in-active::before {
      color: #000;
    }
    .search-widget-field--occupied__out-fill,
    .search-widget-field--occupied__in-fill {
      color: #000;
    }

    &-field--guest {
      border-radius: 0 50px 50px 0;
      display: inline-block;
      width: 100%;
      min-width: 214px;
      background: #fff;

      :deep(.select-guests--btn) {
        margin: 0;
        padding: 0 18px;
        min-width: auto;
        max-width: auto;
        background: initial;
        &.active {
          box-shadow: none;
        }
        .title {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #5a5d63;
        }
        .body {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
        .ico-arrow {
          display: none;
        }
      }
    }

    &--link-city {
      position: absolute;
      bottom: 40px;
      left: 15px;
      right: 15px;
      z-index: 1;
      .btn-link-city {
        width: 100%;
        max-width: 990px;
        margin: 0 auto;
        text-align: left;
        display: flex;
        justify-content: flex-end;
        font-size: 15px;
        line-height: 1.4;
        color: #ffffff;
        cursor: pointer;

        .icon-location {
          padding-top: 4px;
        }
        .text-small {
          font-size: 13px;
        }
        :deep(.icon-app-location) {
          font-size: 30px;
          margin-right: 6px;
        }
      }
    }
  }
  .search-widget-field__right {
    padding-right: 4px;
    display: flex;
    z-index: 2;
    border-radius: 0 50px 50px 0;
    background: #fff;
    &.active {
      background: #f8faff;
    }
  }

  .search-widget-field__right {
    padding-right: 4px;
    display: flex;
    z-index: 2;
    border-radius: 0 50px 50px 0;
    background: #fff;
    &.active {
      background: #f8faff;
    }
  }

  @media (min-width: 930px) {
    .search-widget-field.active {
      // max-width: 100%;

      &::before {
        content: "";
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0.1;
        background: var(--color-main-black);
        pointer-events: none;
      }

      .search-widget-field--suggestions {
        min-width: 333px;
      }

      :deep(.sc-datepickerext-wrapper) {
        overflow: visible;
      }
    }
  }
}

.white-label-domclick .search-widget-field__apply {
  background: #009E36;
}
</style>
