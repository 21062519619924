
<i18n lang="json">
{
	"ru": {
		"datepicker": {
      "tooltip": "Выберите даты поездки, чтобы увидеть цены"
    },
    "today": "Сегодня",
    "tomorrow": "Завтра",
    "weekends": "Выходные"
	},
  "en": {
		"datepicker": {
      "tooltip": "Choose your travel dates to see prices"
    },
    "today": "Today",
    "tomorrow": "Tomorrow",
    "weekends": "Weekends"
	}
}
</i18n>
<template>
  <transition name="sc-datepickerext-wrapper--fade">
    <div
      v-if="visible"
      class="sc-datepickerext-wrapper"
      :class="{
        desktop: !this.$parent.isMobile,
        mobile: this.$parent.isMobile
      }"
    >
      <div class="sc-datepickerext-wrapper-body">
        <div v-if="!isBegin" class="sc-datepickerext-wrapper-prev" @click="prev()" data-cy="sc-datepickerext-prev"></div>
      <div v-else class="sc-datepickerext-wrapper-prev empty"></div>
      <div v-if="!isEnd" class="sc-datepickerext-wrapper-next" @click="next()" data-cy="sc-datepickerext-next"></div>
      <div v-else class="sc-datepickerext-wrapper-next empty"></div>
        <slot></slot>
        <div class="sc-datepickerext-indicate-dates">
          <div 
            class="sc-datepickerext-indicate-dates-item" 
            @click="inputDate(today, 'today')"
            :class="{active: activeToday}"
          >{{ this.t("today")}} {{ getDatePeriod(today.in, today.out) }}</div>
          <div 
            class="sc-datepickerext-indicate-dates-item" 
            @click="inputDate(tomorrow, 'tomorrow')"
            :class="{active: activeTomorrow}"
          >{{ this.t("tomorrow")}} {{ getDatePeriod(tomorrow.in, tomorrow.out) }}</div>
          <div 
            v-if="!weeksEqualDays"
            class="sc-datepickerext-indicate-dates-item" 
            @click="inputDate(weeks, 'weeks')"
            :class="{active: activeWeeks}"
          >{{ this.t("weekends")}} {{ getDatePeriod(weeks.in, weeks.out) }}</div>
        </div>
      </div>
      <transition name="slide">
        <div v-if="this.$parent.showHeader" class="sc-datepickerext-wrapper-header">
          <!-- <div class="sc-datepickerext-wrapper-header--bulb"></div> -->
          <div class="sc-datepickerext-wrapper-header--tooltip">
            {{ this.t("datepicker.tooltip") }}
          </div>
          <!-- <div
            class="sc-datepickerext-wrapper-header--close-after"
            @click="$parent.close('another')"
          >
            {{ this.t("datepicker.closeText") }}
          </div> -->
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "BaseModal",
  props: {
    isBegin: {
      type: Boolean,
      required: true
    },
    isEnd: {
      type: Boolean,
      required: true
    },
    date: {
      type: Object,
      required: false
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      visible: false,
      today: {
        in: null,
        out: null
      },
      tomorrow: {
        in: null,
        out: null
      },
      weeks: {
        in: null,
        out: null
      }
    };
  },
  mounted() {

    let today = new Date()
    today.setHours(0,0,0,0)
    let tomorrow = new Date(new Date(today).setDate(today.getDate() + 1))
    let dayAfterTomorrow = new Date(new Date(tomorrow).setDate(tomorrow.getDate() + 1))
    let saturday = new Date(new Date(today).setDate(today.getDate() + 6 - today.getDay()))
    let sunday = new Date(new Date(saturday).setDate(saturday.getDate() + 1))

    this.today = {
      in: today,
      out: tomorrow
    }
    this.tomorrow = {
      in: tomorrow,
      out: dayAfterTomorrow
    }
    this.weeks = {
      in: saturday,
      out: sunday
    }
  },
  watch: {
    visible(val) {
      this.$parent.$emit('visibleCalendar', val)
    }
  },
  computed: {
    choiceDates() {
      return !!(this.date.in && this.date.out)
    },
    activeToday() {
      return this.checkDates(this.today)
    },
    activeTomorrow() {
      return this.checkDates(this.tomorrow)
    },
    activeWeeks() {
      return this.checkDates(this.weeks)
    },
    weeksEqualDays() {
      return !!(
        new Date(this.today.in).getTime() === new Date(this.weeks.in).getTime() ||
        new Date(this.tomorrow.in).getTime() === new Date(this.weeks.in).getTime()
      )
    }
  },
  methods: {
    checkDates(date) {
      return this.choiceDates && 
        new Date(this.date.in).getTime() === new Date(date.in).getTime() &&
        new Date(this.date.out).getTime() === new Date(date.out).getTime()
          ? true : false
    },
    inputDate(date, type) {
      this.$emit('inputDate', date)
      this.$emit('dateType', type)
      this.$parent.$emit('dateType', type)
    },
    getDatePeriod(first, second){
      if (!(first instanceof Date) && !(second instanceof Date)) return "";

      const location = this.$i18n.locale !== "ru" ? "en-US" : "ru-RU";

      let firstDay = first.toLocaleString(location, {
        day: "numeric",
      });

      let firstMonth = first.toLocaleString(location, {
        month: "short",
      }).slice(0, 3);

      const secondDay = second.toLocaleString(location, {
        day: "numeric",
      }).slice(0, 3);

      const secondMonth = second.toLocaleString(location, {
        month: "short",
      }).slice(0, 3);

      if (first.getMonth() !== second.getMonth()) {
        firstDay = first.toLocaleString(location, {
          day: "numeric",
        });

        firstMonth = first.toLocaleString(location, {
          month: "short",
        }).slice(0, 3);

        return `${firstDay} ${firstMonth} - ${secondDay} ${secondMonth}`;
      }

      return `${firstDay} - ${secondDay} ${secondMonth}`;
    },
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    toggle() {
      this.visible = !this.visible;
    },
    next() {
      this.$emit("next");
    },
    prev() {
      this.$emit("prev");
    }
  }
};
</script>
<style lang="scss" scoped>

.slide-enter-active,
.slide-leave-active {
  margin-top: 0;
  transition: margin-top .2s ease-in;
}
.slide-enter,
.slide-leave-to {
  margin-top: -47px;
}

.sc-datepickerext-wrapper {
  display: flex;
  flex-direction: column;

  &--fade-enter-active,
  &--fade-leave-active {
    @media (min-width: 768px) {
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: 5% 0%;
      transition: transform 0.35s ease, opacity 0.15s ease;
    }

    @media (max-width: 767px) {
      opacity: 1;
      transition: opacity 0.15s ease;
    }
  }
  &--fade-enter,
  &--fade-leave-to {
    @media (min-width: 768px) {
      transform: scale(0, 0);
      opacity: 0;
    }

    @media (max-width: 767px) {
      opacity: 0;
    }
  }


  background-color: white;
  position: absolute;
  z-index: 9999999;
  box-shadow: 0 15px 30px 10px rgba(68, 68, 68, 0.08);
  overflow: hidden;
  // margin-top: 26px;
  // padding-top: 15px;

  .sc-datepickerext-indicate-dates {
    padding: 10px;
    display: flex;
    width: 100%;
    margin-top: 0px;
    grid-gap: 10px;
    // background: #F8FAFF;
    border-top: 1px solid #E7E9F3;

    &-item {
      margin-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-gray-g3);
      backdrop-filter: blur(15px);
      padding: 3px 11px;
      height: 22px;
      border: none;
      border-radius: 11px;
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: var(--color-main-black);

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        background: #DEE0EC;
        border: none;
      }

      &:active {
        background: #CFD2DF;
      }

      &.active {
        border: none;
      }
    }
  }
  &-header {
    display: block;
    font-size: 17px;
    color: black;
    background: #FFF6E6;
    padding: 14px 20px;
    overflow: hidden;
    border: 1px solid #E7E9F3;
    &--close-after {
      grid-area: close;
      display: flex;
      align-items: center;
      margin-left: auto;
      color: #2d6cb4;
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      line-height: 1;
    }
    &--tooltip {
      grid-area: tooltip;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
    }
    &--bulb {
      align-self: center;
      transform: translate(0, -3px);
      grid-area: bulb;
      min-width: 26px;
      width: 26px;
      height: 24px;
      background-image: url("~/assets/img/lamp.svg");
    }
  }
  &-body {
    position: relative;
    padding: 0;
  }
  &-prev,
  &-next {
    background-image: url("~/assets/img/arrow-black.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    display: inline-block;
    margin-top: 0;
    height: 32px;
    width: 32px;
    fill: #e4e7e7;
    stroke-width: 1;
    // float: left;
    position: absolute;
    left: 29px;
    top: 26px;
    z-index: 2;
    &.empty {
      background: none;
      cursor: default;
    }
  }
  &-prev {
    transform: scale(1);
  }
  &-next {
    left: initial;
    right: 29px;
    transform: scale(-1, 1);
  }
}
</style>
