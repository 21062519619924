import HTTP from "../http-common";
/**
 * Получение списка локаций
 *
 * @module getTermSuggestions
 * @return {object} Promise
 */
export default (query, abortController) => {
  return HTTP.get("json/search/searchObjectsPr", {
    cache: false,
    adapter: null,
    params: {...query},
    signal: abortController.signal,
    headers: {
      "api-version": String(null), // Если указать версию, то будет минимум данных
    },
  });
};
