import { cacheApi } from "../http-common";

/**
 * Получение данных о странах и городах из файла JSON,
 * который расположен ./public/countries.json
 *
 * @module getLocations
 * @return {object} Promise
 */
export default (locationId, locationType) =>
  cacheApi.get({
    url: "/json/locations/getLocationByIdAndType",
    config: {
      params: {
        locationId,
        locationType,
      },
      headers: {
        "api-version": 1.9, // Если указать версию, то будет минимум данных
      },
      cache: false,
    },
    expire: 86400000,
  });
