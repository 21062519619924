<template>
  <div v-if="modelValue" class="ui-modal-light__bg" :class="{mobile: isMobile}">
    <!-- <div class="ui-modal-light__wrapper"> -->
      <div
        ref="modalMain"
        class="ui-modal-light"
        :class="{
          'big-size': isModalBigSize,
          'ui-modal-light_full-br': hideCloseBtn,
        }"
      >
        <div
          v-if="!hideCloseBtn"
          class="ui-modal-light__close"
          @click="close"
        >
          <span class="icon-app-close-btn"></span>
        </div>
        <slot></slot>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { documentBody } from "@sutochno/utils";

export default {
  name: "UIModalLight",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    hideCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      isModalBigSize: false,
    }
  },
  watch: {
    modelValue(val) {
      if (val) {
        documentBody.overflow("hidden");
      } else {
        documentBody.overflow("");
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
  },
  updated(){
    const modalHeight = this.$refs.modalMain?.offsetHeight;
    const modalWrapperHeight = this.$refs.modalMain?.parentNode?.clientHeight;

    if (modalHeight && modalWrapperHeight && modalHeight >= modalWrapperHeight - 80) {
      this.isModalBigSize = true;
      return;
    }
    this.isModalBigSize = false;
  }
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden !important;
}
.ui-modal-light__bg {
  padding-top: 80px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.24);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}
.ui-modal-light {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.1),
    0px 6px 10px rgba(20, 45, 136, 0.02);
  border-radius: 20px 0px 20px 20px;
  &_full-br {
    border-radius: 20px;
  }
}
.big-size{
  max-height: calc(100vh - 80px);
  border-radius: 20px 0px 0px 0px;
}
.ui-modal-light__close {
  position: absolute;
  right: -14px;
  top: -14px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1c1c;
  border-radius: 100%;
  cursor: pointer;

  span {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
  }
}

</style>
