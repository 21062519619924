import { Objects } from "@/components/api/";

const state = () => ({
  superGuestPrices: [],
  isSpecialPricesloaded: false,
});

const getters = {
  isSuperGuest: (state, getters, rootState) => {
    const permissions = rootState?.user?.userData?.user?.permissions || [];
    return permissions.includes('isSuperGuest');
  },
  getSuperGuestPriceById: (state) => (id) => {
    return state.superGuestPrices.find(item => item.id === id);
  },
  getIsSpecialPricesloaded: (state) => {
    return state.isSpecialPricesloaded;
  },
};

const actions = {
  async getCalculateSpecialPrices({ state, commit }, { params, cancelSource }) {
    commit('setIsSpecialPricesloaded', false);
    try {
      const calculateSpecialPrices = await Objects.calculateSpecialPrices(params, cancelSource);
      const specialPrices = calculateSpecialPrices?.data?.data?.objects.filter(item => item.changes);

      const specialPricesIds = specialPrices.map(item => item.id);
      const oldSuperGuestPrices = state.superGuestPrices.filter(item => !specialPricesIds.includes(item.id))

      commit('setSuperGuestPrices', [...oldSuperGuestPrices, ...specialPrices]);
    } catch(e) {
      console.error('error getCalculateSpecialPrices', e);
    } finally {
      commit('setIsSpecialPricesloaded', true);
    }
  }
};

// mutations
const mutations = {
  setSuperGuestPrices: (state, payload) => {
    state.superGuestPrices = payload;
  },
  setIsSpecialPricesloaded: (state, payload) => {
    state.isSpecialPricesloaded = payload;
  },
};

export default {
  namespaced: true,
  namespace: "superGuest",
  state,
  getters,
  actions,
  mutations,
};
