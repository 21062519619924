import HTTP from "../http-common";
/**
 * Получение списка локаций
 *
 * @module getTermSuggestions
 * @return {object} Promise
 */
export default (query) => {
  return HTTP.get("rest/search/getTermSuggestions?query=" + query, {
    headers: {
      "api-version": String(null), // Если указать версию, то будет минимум данных
    },
  }).then((v) => v.data.data.suggestions);
};
