import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_TLJV_ZOrmcF5eWWMgHgwvrMmBSP_x_MiTTHMkfm2xus from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_kNbg80s6qe3kc87z2nZCvKsWnzwgKnky7UDHZZ7lmIY from "/app/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import _01_1_setup_client_X2_w9V92OkhLGs_rfCiIyog0JRcjjWDCWeLj47WlBYI from "/app/plugins/01.1.setup.client.js";
import _01_i18n_dIO_STu2wAReGkbaNPX0G8sold3D8HW8_nD2fpzE48s from "/app/plugins/01.i18n.js";
import _01_legacyMain_xS_6lUDh30xd1_GNhgt_Y0KXDwCA_3GY92GNRtMe8mA from "/app/plugins/01.legacyMain.js";
import _01_node_client_3hjsPFLebIrOFRwdPvqDsEcxUDLYn_g2CxVN7oguhwo from "/app/plugins/01.node.client.js";
import _02_store_6yOsD_aJUvzTLcY_oZ2OLNxp2XOPfNSaBBqUv_r60R4 from "/app/plugins/02.store.js";
import _03_cookies_lYsfsrnWfdiQ1r1E3MfBUjo_KGzoXR_B4JdJsp_EfgE from "/app/plugins/03.cookies.js";
import _04_gtag_S6yUqK41MeAtMhjCeKC1_rIVzTUwtUfg3d5eN5_z61E from "/app/plugins/04.gtag.js";
import _05_gtm___gmPpELXupWZxn9Qv5p_NFZphOznhHvDI97hoTpPYo from "/app/plugins/05.gtm.js";
import _06_panZoom_zPTBLveqb1KsTxYkbiqAsGSQWvhwDZIlLReFKL9hVLs from "/app/plugins/06.panZoom.js";
import _07_clickOutside_4ZjF3dhR_7bMz5iFVqVN_ymqmdbTdaWT0glsUPsNmhM from "/app/plugins/07.clickOutside.js";
import _08_vueSafeTeleport_bh9Hgf5jvny2qZd1EKdt_QsWUo71Cg3YkGKU4F7Xguc from "/app/plugins/08.vueSafeTeleport.js";
import _09_customDirectives_LGWYD_IJxkzfxR__0jeZjt_Ge_9516D9wHiozwgedmQ from "/app/plugins/09.customDirectives.js";
import _10_viewPort_client_Uz2dTtKUfRonpF6DFeJaay57ENgTsN0_hGALD7r_hiI from "/app/plugins/10.viewPort.client.js";
import toaster_nOyHZeLGopTLXuH7St3b3huALbgPqFHPIQxFdS_Qhr4 from "/app/plugins/toaster.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  pwa_client_TLJV_ZOrmcF5eWWMgHgwvrMmBSP_x_MiTTHMkfm2xus,
  plugin_client_kNbg80s6qe3kc87z2nZCvKsWnzwgKnky7UDHZZ7lmIY,
  _01_1_setup_client_X2_w9V92OkhLGs_rfCiIyog0JRcjjWDCWeLj47WlBYI,
  _01_i18n_dIO_STu2wAReGkbaNPX0G8sold3D8HW8_nD2fpzE48s,
  _01_legacyMain_xS_6lUDh30xd1_GNhgt_Y0KXDwCA_3GY92GNRtMe8mA,
  _01_node_client_3hjsPFLebIrOFRwdPvqDsEcxUDLYn_g2CxVN7oguhwo,
  _02_store_6yOsD_aJUvzTLcY_oZ2OLNxp2XOPfNSaBBqUv_r60R4,
  _03_cookies_lYsfsrnWfdiQ1r1E3MfBUjo_KGzoXR_B4JdJsp_EfgE,
  _04_gtag_S6yUqK41MeAtMhjCeKC1_rIVzTUwtUfg3d5eN5_z61E,
  _05_gtm___gmPpELXupWZxn9Qv5p_NFZphOznhHvDI97hoTpPYo,
  _06_panZoom_zPTBLveqb1KsTxYkbiqAsGSQWvhwDZIlLReFKL9hVLs,
  _07_clickOutside_4ZjF3dhR_7bMz5iFVqVN_ymqmdbTdaWT0glsUPsNmhM,
  _08_vueSafeTeleport_bh9Hgf5jvny2qZd1EKdt_QsWUo71Cg3YkGKU4F7Xguc,
  _09_customDirectives_LGWYD_IJxkzfxR__0jeZjt_Ge_9516D9wHiozwgedmQ,
  _10_viewPort_client_Uz2dTtKUfRonpF6DFeJaay57ENgTsN0_hGALD7r_hiI,
  toaster_nOyHZeLGopTLXuH7St3b3huALbgPqFHPIQxFdS_Qhr4
]