import { createStore } from "vuex";
import application from "./store/modules/application";
import search from "./store/modules/search";
import searchPr from "./store/modules/searchPr";
import hotel from "./store/modules/hotel";
import booking from "./store/modules/booking";
import detail from "./store/modules/detail";
import modal from "./store/modules/modal";
import detailBooking from "./store/modules/detailBooking";
import filter from "./store/modules/filter";
import superGuest from "./store/modules/superGuest";
import payments from "./store/modules/payments";

import { store as user } from "@sutochno/header";
// import user from "@/components/Header/src/store.js";
import { isWhiteLabel, whiteLabelInfo } from "@sutochno/whitelabel";
import favorites from "./store/modules/favorites";

import sutochnoENV from "@sutochno/env";

const debug = sutochnoENV.DEV;
export default defineNuxtPlugin(({ vueApp }) => {
  const store = createStore({
    state() {
      return {
        useCookie,
        DOMAIN: vueApp.config.globalProperties.$DOMAIN,
      }
    },
    modules: {
      application,
      user,
      search,
      searchPr,
      hotel,
      booking,
      detail,
      modal,
      detailBooking,
      payments,
      filter,
      superGuest,
      favorites,
    },
    strict: debug,
  })

  store.commit("user/setIsNuxt", true);
  if (isWhiteLabel) {
    // клонирование помогает избежать утечки памяти
    store.dispatch("user/setWhitelabel", Object.assign({}, whiteLabelInfo));
  }

  vueApp.use(store);
});
