import HTTP from "../http-common";

/**
 * Получение специальных цен для гостя
 *
 * @module calculateSpecialPrices
 * @return {object} Promise
 */

export default (payload) =>
  HTTP.post("json/objects/calculateSpecialPrices", payload, {
    headers: {
      "api-version": '2.0',
      "Content-Type": "application/json",
    },
    cache: false,
  });
  