<template>
  <button
    class="ui-button"
    :class="[
      theme,
      size && 'ui-button_' + size,
    ]"
    :disabled="disabled"
  >
    <ul class="loader" v-show="loading">
      <li class="loader__dot"></li>
      <li class="loader__dot"></li>
      <li class="loader__dot"></li>
    </ul>
    <div :class="{'ui-button--hide': loading}">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "UIButton",
  data() {
    return {
      buttonThemeList: ["default", "red", "dark", "pale-blue", "light", "gray", 'empty'],
    };
  },
  props: {
    buttonTheme: String,
    size: String,
    loading: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
  },
  computed: {
    theme() {
      if (this.buttonThemeList.includes(this.buttonTheme)) {
        return this.buttonTheme;
      }
      return "default";
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-button {
  position: relative;
  padding: 14px 24px;
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 14px;
  border: none;
  outline: none;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1c1c1c;

  transition: all 0.2s;

  &--hide{
    opacity: 0;
  }

  &_small {
    padding: 8px;
    letter-spacing: 0.2px;
    border-radius: 8px;
    @include font(12px, 16px, 500);
  }
}

.empty {
  border: 1px solid #C0C3D3;
  color: #1C1C1C;
  background: none;
}

.default {
  background: #ffffff;
  border: 1px solid #e7e9f3;

  &:hover {
    border: 1px solid #c0c3d3;
  }
  &:active {
    background: #f8faff;
    border: 1px solid #c0c3d3;
  }
  &:disabled {
    border: 1px solid #e7e9f3;
    color: rgba(28, 28, 28, 0.2);
  }
}

.gray {
  background: #E7E9F3;
  border: none;
}

.red {
  background: #f51449;
  color: #ffffff;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #f51449;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      #f51449;
  }
  &:disabled {
    background: rgba(245, 20, 73, 0.2);
  }
}

.dark {
  background: #1c1c1c;
  color: #ffffff;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      #1c1c1c;
  }
  &:active {
    background: #000000;
  }
  &:disabled {
    background: rgba(28, 28, 28, 0.2);
  }
}

.pale-blue {
  background: #f1f3fb;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      #f4f5f9;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      #f4f5f9;
  }
  &:disabled {
    background: #f1f3fb;
    color: rgba(28, 28, 28, 0.2);
  }
}
.mobile .pale-blue{
  &:hover {
    background: #f1f3fb;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      #f4f5f9;
  }
  &:disabled {
    background: #f1f3fb;
    color: rgba(28, 28, 28, 0.2);
  }
}

.light {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.1),
    0px 6px 10px rgba(20, 45, 136, 0.02);

  &:hover {
    box-shadow: 0px 3px 5px rgba(20, 45, 136, 0.1),
      0px 6px 10px rgba(20, 45, 136, 0.02);
  }
  &:active {
    box-shadow: 0px 1px 3px rgba(20, 45, 136, 0.1),
      0px 6px 9px rgba(20, 45, 136, 0.02);
  }
  &:disabled {
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.1),
      0px 6px 10px rgba(20, 45, 136, 0.02);
    color: rgba(28, 28, 28, 0.2);
  }
}


.loader-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader{
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  list-style: none;

  &__dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    animation: dots 1.5s ease-in-out infinite;
    transform: scale(0);

    &:nth-child(2){
        animation-delay: .25s;
    }
    &:nth-child(3){
        animation-delay: .5s;
    }
  }
}
@keyframes dots {
  0%, 60%, 100% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
}
.default .loader, .pale-blue .loader, .light .loader__dot{
   background: #1c1c1c;
}
</style>
