
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as BookingWLQqR6iuuH_OSRyCGlgtarYYtIUuAPqSo8082l5wDHLZIMeta } from "/app/pages/Booking/BookingWL.vue?macro=true";
import { default as index5M0g_45e48KmSrU5MqbaSMw4h34rV_45el_3MxgEvSsh2cMMeta } from "/app/pages/Booking/index.vue?macro=true";
import { default as LightBookingQPJrsf8lxu9uxVnj2rREsf3aWHuonvqy2vTvuzEIXxAMeta } from "/app/pages/Booking/LightBooking.vue?macro=true";
import { default as DetailFotoVwKTSGcPNrdMT5XtlV1LnSrQxlRcuwFzeML4PzBhEtIMeta } from "/app/pages/Detail/DetailFoto.vue?macro=true";
import { default as indexlNGeIbS4Yd2pySHME1GnQuGvYWUgthleln8NxNOowJwMeta } from "/app/pages/Detail/index.vue?macro=true";
import { default as Review93bcblHWTiH_45dKfPOB1rN2KZsY1gYCRENLgLWVC2UGMMeta } from "/app/pages/Detail/Review.vue?macro=true";
import { default as ReviewCommentqCcnVmPLEM_45PQ8oLbAWXcMv5rotonhxXnlTFd2LbfwUMeta } from "/app/pages/Detail/ReviewComment.vue?macro=true";
import { default as ReviewLocationsEs5P9uOPiVXhs4J0BFISg_mhmKaUpQxCLMGQVSOsEYMeta } from "/app/pages/Detail/ReviewLocation.vue?macro=true";
import { default as FeedbackLnGpxKFnMHMb4_45el5xwr9I9epFw6meg9TvB4OkMMaKMMeta } from "/app/pages/Feedback.vue?macro=true";
import { default as HotelB2RWw66_45leFnS49dk5vINIpTaxhJRIa1vsYLEPOrB44Meta } from "/app/pages/Hotel.vue?macro=true";
import { default as MainBVWPIkaSdm124dp0kwUhvuVfRSlhVNkz_9Z2MyaaHoYMeta } from "/app/pages/Main.vue?macro=true";
import { default as NoticesFrameF3ZUH8xzwDLTohKD9gBloHObehSfVAZIa3WMNuPfrNcMeta } from "/app/pages/NoticesFrame.vue?macro=true";
import { default as PaymentsWD9xjfpRwmUT_Orjy6joF_mj85pCUPcPaMr_6IXCymIMeta } from "/app/pages/Payments.vue?macro=true";
import { default as PaymentsFrameQ8ImMPRoC_45r1iwA6RsVN_45feb94La61yjxamPj3kXRz8Meta } from "/app/pages/PaymentsFrame.vue?macro=true";
import { default as SearchEuMEYEkHFV5K0NVRaq10Lif1sr_45abWnge16s8wzddHsMeta } from "/app/pages/Search.vue?macro=true";
import { default as SpaywwXYMMeZ_457yH9QsyspItdTOxsW3rsTfMVmRtZjR0RFoMeta } from "/app/pages/Spay.vue?macro=true";
export default [
  {
    name: "BookingWL",
    path: "/booking/",
    meta: BookingWLQqR6iuuH_OSRyCGlgtarYYtIUuAPqSo8082l5wDHLZIMeta || {},
    component: () => import("/app/pages/Booking/BookingWL.vue")
  },
  {
    name: "Booking",
    path: "/booking/:orderCode",
    meta: index5M0g_45e48KmSrU5MqbaSMw4h34rV_45el_3MxgEvSsh2cMMeta || {},
    component: () => import("/app/pages/Booking/index.vue")
  },
  {
    name: "LightBooking",
    path: "/booking/light/:orderCode",
    meta: LightBookingQPJrsf8lxu9uxVnj2rREsf3aWHuonvqy2vTvuzEIXxAMeta || {},
    component: () => import("/app/pages/Booking/LightBooking.vue")
  },
  {
    name: "DetailFoto",
    path: "/detail/:id/foto",
    meta: DetailFotoVwKTSGcPNrdMT5XtlV1LnSrQxlRcuwFzeML4PzBhEtIMeta || {},
    component: () => import("/app/pages/Detail/DetailFoto.vue")
  },
  {
    name: "Detail",
    path: "/detail/:id",
    meta: indexlNGeIbS4Yd2pySHME1GnQuGvYWUgthleln8NxNOowJwMeta || {},
    component: () => import("/app/pages/Detail/index.vue")
  },
  {
    name: "Review",
    path: "/detail/:objectId/review/:orderId?",
    meta: Review93bcblHWTiH_45dKfPOB1rN2KZsY1gYCRENLgLWVC2UGMMeta || {},
    component: () => import("/app/pages/Detail/Review.vue")
  },
  {
    name: "ReviewComment",
    path: "/detail/:objectId/review/:orderId?/step2",
    meta: ReviewCommentqCcnVmPLEM_45PQ8oLbAWXcMv5rotonhxXnlTFd2LbfwUMeta || {},
    component: () => import("/app/pages/Detail/ReviewComment.vue")
  },
  {
    name: "ReviewLocation",
    path: "/detail/:objectId/review/:orderId?/step3",
    meta: ReviewLocationsEs5P9uOPiVXhs4J0BFISg_mhmKaUpQxCLMGQVSOsEYMeta || {},
    component: () => import("/app/pages/Detail/ReviewLocation.vue")
  },
  {
    name: "Feedback",
    path: "/about/feedback",
    meta: FeedbackLnGpxKFnMHMb4_45el5xwr9I9epFw6meg9TvB4OkMMaKMMeta || {},
    component: () => import("/app/pages/Feedback.vue")
  },
  {
    name: "Hotel",
    path: "/hotels/:hotelId",
    meta: HotelB2RWw66_45leFnS49dk5vINIpTaxhJRIa1vsYLEPOrB44Meta || {},
    component: () => import("/app/pages/Hotel.vue")
  },
  {
    name: "Main",
    path: "/",
    meta: MainBVWPIkaSdm124dp0kwUhvuVfRSlhVNkz_9Z2MyaaHoYMeta || {},
    component: () => import("/app/pages/Main.vue")
  },
  {
    name: "NoticesFrame",
    path: "/noticesFrame",
    meta: NoticesFrameF3ZUH8xzwDLTohKD9gBloHObehSfVAZIa3WMNuPfrNcMeta || {},
    component: () => import("/app/pages/NoticesFrame.vue")
  },
  {
    name: "Payments",
    path: "/payments/:paymentsUrl",
    meta: PaymentsWD9xjfpRwmUT_Orjy6joF_mj85pCUPcPaMr_6IXCymIMeta || {},
    component: () => import("/app/pages/Payments.vue")
  },
  {
    name: "PaymentsFrame",
    path: "/paymentsFrame",
    meta: PaymentsFrameQ8ImMPRoC_45r1iwA6RsVN_45feb94La61yjxamPj3kXRz8Meta || {},
    component: () => import("/app/pages/PaymentsFrame.vue")
  },
  {
    name: "Search",
    path: "/search",
    meta: SearchEuMEYEkHFV5K0NVRaq10Lif1sr_45abWnge16s8wzddHsMeta || {},
    component: () => import("/app/pages/Search.vue")
  },
  {
    name: "PagePay",
    path: "/spay/:orderId",
    meta: SpaywwXYMMeZ_457yH9QsyspItdTOxsW3rsTfMVmRtZjR0RFoMeta || {},
    component: () => import("/app/pages/Spay.vue")
  }
]