import sutochnoENV from "@sutochno/env";

import validationConfig from "./legacyMain/validation-config";
import vh from "./legacyMain/vh";
import filters from "./legacyMain/filters";

export default defineNuxtPlugin(({ vueApp }) => {
  const $t = vueApp.config.globalProperties.$t;
  const DOMAIN = sutochnoENV.VITE_API_ADDRESS.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/)[1];

  validationConfig($t);
  vh();

  return {
    provide: {
      DOMAIN,
      filters,
    }
  }
});