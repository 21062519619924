import { useStore } from "vuex";

export default function () {
  const store = useStore();
  const wlExceptionsList = ["utair", "aeroflot", "leveltravel", "pobeda", "afl"];
  const whitelabel = store.state.user?.whitelabel;

  const isNotShowFooter = computed(() => {
    return wlExceptionsList?.includes(whitelabel?.name);
  })

  return {
    isNotShowFooter
  };
}
